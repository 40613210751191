import React from 'react'
import { useState } from 'react';
import { createContext } from 'react'
import { Product } from '../pages/shop/product';
import { PRODUCTS } from '../products';

export const ShopContext = createContext(null);

// to sort the item or get item
const getDefaultCart = () => {
    let cart = {}
    for (let i =1; i<PRODUCTS.length + 1; i++){
        cart[i]= 0;
    }
    return cart;
};

export const ShopContextProvider= (props) => {
    const [cartItems, setCartItems] = useState(getDefaultCart());

    const getTotalCartAmount = () => {
        let totalAmount = 0;
        for (const item in cartItems) {
            if (cartItems[item] > 0){
                let itemInfo = PRODUCTS.find((product) => product.id === Number(item));
                totalAmount += cartItems[item] * itemInfo.price 
            }
        }
        return totalAmount;
    };




    // TO function add and Minus into cart
    const addToCart = (itemId) =>{
        setCartItems ((prev) => ({...prev, [itemId]: prev [itemId] + 1 }))
    };
    const removeFromCart = (itemId) =>{
        setCartItems ((prev) => ({...prev, [itemId]: prev [itemId] - 1 }))
    };

    const updateCartItemCount = (newAmount, itemId) => {
        setCartItems((prev) => ({...prev, [itemId]: newAmount}))
    }


    // Gett value
    const contextValue = {cartItems, addToCart, removeFromCart, updateCartItemCount, getTotalCartAmount};



  return <ShopContext.Provider value = {contextValue}>{props.children}</ShopContext.Provider>
  




};
