
import React, {useContext} from 'react'
import { ShopContext } from '../../context/shop-context';
import "./cart.css";

// Get the item from shop
export const CartItem = (props) => {
    const{id,  productName, price ,productImage} = props.data;
    const {cartItems, addToCart , removeFromCart, updateCartItemCount} = useContext(ShopContext)

  return (
    // Description of CartItem
    <div className="cartITem">
      <img src = {productImage}/>
      <div className="description"> 
          <p><b> {productName}</b></p>
          <p>${price}</p>

          {/* Button for Minus and add the item */}
          <div className="countHandler">
            <button onClick={()=> removeFromCart (id)}> - </button>
            <input value = {cartItems[id]} onChange ={(e) => updateCartItemCount (Number(e.target.value),id)} />
            <button onClick={()=> addToCart (id)}> +</button>
          </div>
      </div> 
    </div>
  )
}
