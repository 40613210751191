import React from 'react'
import { PRODUCTS } from "../../products" // ALL the producst from 'products.js' array 
import { Product  } from './product'; //Get Product Image, name, etc..
import"./shop.css";

export const Shop = () => {
  return (
    <div className='shop'>
      <div className="shopTitle">
        <h1> Samtech Shop</h1>
      </div>
      <div className='products'> {PRODUCTS.map((product)=> <Product  data = {product}/>)}

      </div>
    </div>
  )
}
